import React from 'react';

const BookIcon = props => (
	<svg
		width={props.width}
		height={props.height}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.56906 10.9863C5.91464 10.9863 6.19477 10.724 6.19477 10.4004V6.26953C6.19477 5.94592 5.91464 5.68359 5.56906 5.68359C5.22349 5.68359 4.94336 5.94592 4.94336 6.26953V10.4004C4.94336 10.724 5.22349 10.9863 5.56906 10.9863Z"
			fill={props.fill}
			fillOpacity="0.9"
		/>
		<path
			d="M12.5762 4.80469V9.22851C12.5762 9.55212 12.8563 9.81444 13.2019 9.81444C13.5475 9.81444 13.8276 9.55212 13.8276 9.22851V4.80469C13.8276 4.48107 13.5475 4.21875 13.2019 4.21875C12.8563 4.21875 12.5762 4.48107 12.5762 4.80469Z"
			fill={props.fill}
			fillOpacity="0.9"
		/>
		<path
			d="M15.3923 9.78516C15.7379 9.78516 16.018 9.52283 16.018 9.19922V0.585938C16.018 0.262324 15.7379 0 15.3923 0H10.9811C10.6355 0 10.3554 0.262324 10.3554 0.585938V2.34375H8.38443V1.8457C8.38443 1.52209 8.1043 1.25977 7.75873 1.25977H3.97322V0.585938C3.97322 0.262324 3.69309 0 3.34751 0H0.625704C0.280128 0 0 0.262324 0 0.585938V14.4141C0 14.7377 0.280128 15 0.625704 15H15.3923C15.7379 15 16.018 14.7377 16.018 14.4141V12.0703C16.018 11.7467 15.7379 11.4844 15.3923 11.4844H11.6068V1.17188H14.7666V9.19922C14.7666 9.52283 15.0467 9.78516 15.3923 9.78516ZM2.72181 13.8281H1.25141V12.6562H2.72181V13.8281ZM2.72181 11.4844H1.25141V3.51562H2.72181V11.4844ZM2.72181 2.34375H1.25141V1.17188H2.72181V2.34375ZM7.13302 13.8281H3.97322V2.43164H7.13302V13.8281ZM10.3554 13.8281H8.38443V5.85938H10.3554V13.8281ZM10.3554 4.6875H8.38443V3.51562H10.3554V4.6875ZM14.7666 12.6562V13.8281H11.6068V12.6562H14.7666Z"
			fill={props.fill}
			fillOpacity="0.9"
		/>
	</svg>
);

export default BookIcon;
