//authentication
export const SET_ERROR = 'SET_ERROR';
export const RESET_ERROR = 'RESET_ERROR';

// book
export const SET_CURRENT_BOOK = 'SET_CURRENT_BOOK';
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export const FETCH_CURRENT_BOOK = 'FETCH_CURRENT_BOOK';

// library
export const FETCH_USERS_BOOKS = 'FETCH_USERS_BOOKS';
export const SET_CURRENT_SHELF = 'SET_CURRENT_SHELF';
export const FETCH_USERS_SHELVES = 'FETCH_USERS_SHELVES';
export const UPDATE_BOOK_FAVORITE = 'UPDATE_BOOK_FAVORITE';
export const UPDATE_BOOK_READING_STATUS = 'UPDATE_BOOK_READING_STATUS';
export const ADD_BOOK_TO_LIBRARY = 'ADD_BOOK_TO_LIBRARY';
export const DELETE_USER_BOOK = 'DELETE_USER_BOOK';
export const MOVE_BOOK_FROM_SHELF = 'MOVE_BOOK_FROM_SHELF';
export const UPDATE_BOOK_USER_RATING = 'UPDATE_BOOK_USER_RATING';
export const UPDATE_SINGLE_BOOK_FIELD = 'UPDATE_SINGLE_BOOK_FIELD';

// shelf
export const CREATE_USER_SHELF = 'CREATE_USER_SHELF';
export const CREATE_USER_SHELF_SUCCESS = 'CREATE_USER_SHELF_SUCCESS';
export const CREATE_USER_SHELF_FAILURE = 'CREATE_USER_SHELF_FAILURE';
export const FETCH_USER_SHELF = 'FETCH_USER_SHELF'
export const FETCH_USER_SHELF_SUCCESS = 'FETCH_USER_SHELF_SUCCESS';
export const FETCH_USER_SHELF_FAILURE = 'FETCH_USER_SHELF_FAILURE';
export const ADD_BOOK_TO_SHELF = 'ADD_BOOK_TO_SHELF';
export const DELETE_BOOK_FROM_SHELF = 'DELETE_BOOK_FROM_SHELF';
export const MOVE_BOOK_TO_DIFFERENT_SHELF = 'MOVE_BOOK_TO_DIFFERENT_SHELF';
export const FETCH_USER_BOOKS_ON_SHELVES = 'FETCH_USER_BOOKS_ON_SHELVES'
export const FETCH_USER_BOOKS_ON_SHELVES_SUCCESS = 'FETCH_USER_BOOKS_ON_SHELVES_SUCCESS';
export const FETCH_USER_BOOKS_ON_SHELVES_FAILURE = 'FETCH_USER_BOOKS_ON_SHELVES_FAILURE';

// search
export const FETCH_SEARCH_START = 'FETCH_SEARCH_START';
export const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH_SUCCESS';
export const FETCH_SEARCH_FAILURE = 'FETCH_SEARCH_FAILURE';
export const LOAD_MORE = 'LOAD_MORE';
export const FETCH_LOAD_MORE = 'FETCH_LOAD_MORE';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const SET_QUERY = 'SET_QUERY';

//recommendations
export const FETCH_RECOMMEDATIONS_START = 'FETCH_RECOMMEDATIONS_START';
export const FETCH_RECOMMEDATIONS_SUCCESS = 'FETCH_RECOMMEDATIONS_SUCCESS';
export const FETCH_RECOMMEDATIONS_FAILURE = 'FETCH_RECOMMEDATIONS_FAILURE';